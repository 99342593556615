import axios from "axios";
import dayjs from "dayjs";
import lscache from "lscache";
import Vue from "vue";
import {
  MARK_DOWNTIME_AS_READ,
  SAVE_DOWNTIMES,
  SAVE_GEOLOCATION,
  SAVE_READ_DOWNTIMES,
  TOGGLE_MAP,
  TOGGLE_NAVBAR,
  TOGGLE_PRELOAD_FINISHED,
  TOGGLE_SEARCH,
} from "../mutation-types";

export default {
  state: {
    downtimes: [],
    geolocation: null,
    preloadFinished: false,
    readDowntimes: [],
    showMap: !!localStorage.getItem("show_map"),
    showNavbar: false,
    showSearch: false,
  },
  mutations: {
    [MARK_DOWNTIME_AS_READ](state, payload) {
      state.readDowntimes.push(payload.id);
    },
    [SAVE_DOWNTIMES](state, payload) {
      state.downtimes = payload;
    },
    [SAVE_GEOLOCATION](state, payload) {
      state.geolocation = payload;
    },
    [SAVE_READ_DOWNTIMES](state, payload) {
      state.readDowntimes = payload;
    },
    [TOGGLE_MAP](state) {
      state.showMap = !state.showMap;
      if (state.showMap) {
        localStorage.setItem("show_map", 1);
      } else {
        localStorage.removeItem("show_map");
      }
    },
    [TOGGLE_NAVBAR](state) {
      state.showNavbar = !state.showNavbar;
    },
    [TOGGLE_PRELOAD_FINISHED](state) {
      state.preloadFinished = !state.preloadFinished;
    },
    [TOGGLE_SEARCH](state) {
      state.showSearch = !state.showSearch;
    },
  },
  actions: {
    getDowntimes: ({ commit }) => {
      return axios
        .get("scheduleddowntime", Vue.prototype.$auth.apiHeaders())
        .then((response) => {
          commit(SAVE_DOWNTIMES, response.data);

          // Remove read downtimes that don't exist anymore
          const readDowntimes = lscache.get("read_downtimes");
          if (!readDowntimes) {
            return;
          }
          lscache.set(
            "read_downtimes",
            readDowntimes.filter((id) => response.data.some((d) => d.id === id))
          );

          commit(SAVE_READ_DOWNTIMES, readDowntimes);
        });
    },
    markDowntimeAsRead: ({ commit, state }, downtimeId) => {
      const downtime = state.downtimes.find((a) => a.id === downtimeId);
      if (!downtime) {
        console.error(`Downtime with ID ${downtimeId} not found`);
        return;
      }
      commit(MARK_DOWNTIME_AS_READ, downtime);
      // Check local storage for read downtimes and add the passed downtime to the list
      // Use a Set to prevent duplicates
      const readDowntimes = [...new Set(lscache.get("read_downtimes") ?? [])];
      readDowntimes.push(downtimeId);
      lscache.set("read_downtimes", readDowntimes);
    },
    saveGeolocation: ({ commit }, payload) => {
      commit(SAVE_GEOLOCATION, payload);
    },
    toggleMap: ({ commit }) => {
      commit(TOGGLE_MAP);
    },
    toggleNavbar: ({ commit }) => {
      commit(TOGGLE_NAVBAR);
    },
    togglePreloadFinished: ({ commit }) => {
      commit(TOGGLE_PRELOAD_FINISHED);
    },
    toggleSearch: ({ commit }) => {
      commit(TOGGLE_SEARCH);
    },
  },
  getters: {
    unreadAndUpcomingDowntimes: (state) =>
      state.downtimes.filter(
        (a) =>
          !state.readDowntimes.includes(a.id) && dayjs(a.date).isAfter(dayjs())
      ),
  },
};
